// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#notfound {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 40%;
  margin: auto;
  padding: 4.8125rem 0 5.4375rem;
  font-size: 1rem;
  width: 880px;
  height: 480px;
  margin-top: 100px;
  min-width: 600px;
}
#notfound h1 {
  font-size: 6rem;
  font-weight: 700;
  margin: 0;
}
#notfound h2 {
  font-size: 2.56rem;
  font-weight: 700;
  margin-top: 0.5rem;
}
#notfound .custom-button {
  padding-top: 2.1875rem;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1366px) {
  #notfound {
    padding: 1.25rem 1rem 2rem;
    font-size: 0.75rem;
    margin-top: 0.625rem;
    width: 41%;
    height: unset;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/not-found/not-found.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;EACA,8BAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,iBAAA;EACA,gBAAA;AACJ;AACI;EACI,eAAA;EACA,gBAAA;EACA,SAAA;AACR;AAEI;EACI,kBAAA;EACA,gBAAA;EACA,kBAAA;AAAR;AAGI;EACI,sBAAA;EACA,aAAA;EACA,uBAAA;AADR;;AAKA;EACI;IACI,0BAAA;IACA,kBAAA;IACA,oBAAA;IACA,UAAA;IACA,aAAA;EAFN;AACF","sourcesContent":["#notfound {\n    display: flex;\n    flex-direction: column;\n    text-align: center;\n    width: 40%;\n    margin: auto;\n    padding: 4.8125rem 0 5.4375rem;\n    font-size: 1rem;\n    width: 880px;\n    height: 480px;\n    margin-top: 100px;\n    min-width: 600px;\n\n    h1 {\n        font-size: 6rem;\n        font-weight: 700;\n        margin: 0;\n    }\n\n    h2 {\n        font-size: 2.56rem;\n        font-weight: 700;\n        margin-top: 0.5rem;\n    }\n\n    .custom-button {\n        padding-top: 2.1875rem;\n        display: flex;\n        justify-content: center;\n    }\n}\n\n@media screen and (max-width: 1366px) {\n    #notfound {\n        padding: 1.25rem 1rem 2rem;\n        font-size: 0.75rem;\n        margin-top: 0.625rem;\n        width: 41%;\n        height: unset;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
