// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.approval-container {
  background-image: url('approvalBG.svg');
  background-color: var(--ion-light-grey);
  text-align: center;
  padding: 3.125rem 5.625rem;
  height: 100%;
}
.approval-container .image-container {
  padding: 2.375rem;
}
.approval-container .description-container p {
  margin: 0;
}
.approval-container .description-container .description-title {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 0.6875rem;
  color: var(--ion-black-primary);
}
.approval-container .description-container .description-text {
  font-size: 1.125rem;
  color: var(--ion-black-primary);
}

.close-div {
  position: absolute;
  right: 0.3125rem;
  top: 0.3125rem;
}

.close-icon {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/components/approval-waiting/approval-waiting.component.scss"],"names":[],"mappings":"AAAA;EACI,uCAAA;EACA,uCAAA;EACA,kBAAA;EACA,0BAAA;EACA,YAAA;AACJ;AACI;EACI,iBAAA;AACR;AAGQ;EACI,SAAA;AADZ;AAIQ;EACI,kBAAA;EACA,iBAAA;EACA,wBAAA;EACA,+BAAA;AAFZ;AAKQ;EACI,mBAAA;EACA,+BAAA;AAHZ;;AASA;EACI,kBAAA;EACA,gBAAA;EACA,cAAA;AANJ;;AASA;EACI,eAAA;AANJ","sourcesContent":[".approval-container {\n    background-image: url('../../../assets/icon/approvalBG.svg');\n    background-color: var(--ion-light-grey);\n    text-align: center;\n    padding: 3.125rem 5.625rem;\n    height: 100%;\n\n    .image-container {\n        padding: 2.375rem;\n    }\n\n    .description-container {\n        p {\n            margin: 0;\n        }\n\n        .description-title {\n            font-size: 1.75rem;\n            font-weight: bold;\n            margin-bottom: 0.6875rem;\n            color: var(--ion-black-primary);\n        }\n\n        .description-text {\n            font-size: 1.125rem;\n            color: var(--ion-black-primary);\n        }\n\n    }\n}\n\n.close-div {\n    position: absolute;\n    right: 0.3125rem;\n    top: 0.3125rem;\n}\n\n.close-icon {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
