// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header .toolbar {
  --background: transparent;
}
.header .toolbar ion-searchbar {
  padding: 0.1875rem 0;
}
.header .desktop-searchbar-rtl {
  display: block;
}
.header .desktop-searchbar-ltr {
  display: block;
}
.header .mobile-searchbar {
  display: none;
}

.header::after {
  background-image: none;
}

.header::before {
  background-image: none;
}

@media screen and (max-width: 992px) {
  .header .mobile-searchbar {
    display: block;
  }
  .header .desktop-searchbar-rtl {
    display: none;
  }
  .header .desktop-searchbar-ltr {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/search-bar/search-bar.component.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;AAAR;AAEQ;EACI,oBAAA;AAAZ;AAII;EACI,cAAA;AAFR;AAKI;EACI,cAAA;AAHR;AAMI;EACI,aAAA;AAJR;;AAQA;EACI,sBAAA;AALJ;;AAQA;EACI,sBAAA;AALJ;;AAQA;EAEQ;IACI,cAAA;EANV;EASM;IACI,aAAA;EAPV;EAUM;IACI,aAAA;EARV;AACF","sourcesContent":[".header {\n    .toolbar {\n        --background: transparent;\n\n        ion-searchbar {\n            padding: 0.1875rem 0;\n        }\n    }\n\n    .desktop-searchbar-rtl {\n        display: block;\n    }\n\n    .desktop-searchbar-ltr {\n        display: block;\n    }\n\n    .mobile-searchbar {\n        display: none;\n    }\n}\n\n.header::after {\n    background-image: none;\n}\n\n.header::before {\n    background-image: none;\n}\n\n@media screen and (max-width: 992px) {\n    .header {\n        .mobile-searchbar {\n            display: block;\n        }\n\n        .desktop-searchbar-rtl {\n            display: none;\n        }\n\n        .desktop-searchbar-ltr {\n            display: none;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
