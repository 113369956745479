import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';


const routes: Routes = [
	{
		path: '',
		canActivate: [AuthGuard],
		loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
	},
	{
		path: 'registration',
		canActivate: [AuthGuard],
		loadChildren: () => import('./pages/registration-page/registration-page.module').then(m => m.RegistrationPagePageModule)
	},
	{
		path: 'company-details',
		canActivate: [AuthGuard],
		loadChildren: () => import('./pages/registration-page/registration-page.module').then(m => m.RegistrationPagePageModule)
	},
	{
		path: 'two-factor-authentication',
		loadChildren: () => import('./pages/registration-page/registration-page.module').then(m => m.RegistrationPagePageModule)
	},
	{
		path: 'account-details',
		data: {
			breadcrumb: "account",
			url: "/account-details"
		},
		canActivateChild: [AuthGuard],
		loadChildren: () => import('./pages/account-details/account-details.module').then(m => m.AccountDetailsPageModule)
	},
	{
		path: 'login',
		canActivate: [AuthGuard],
		loadChildren: () => import('./pages/registration-page/registration-page.module').then(m => m.RegistrationPagePageModule)
	},
	{
		path: 'forgot-password',
		canActivate: [AuthGuard],
		loadChildren: () => import('./pages/registration-page/registration-page.module').then(m => m.RegistrationPagePageModule)
	},
	{
		path: 'reset-password',
		canActivate: [AuthGuard],
		loadChildren: () => import('./pages/registration-page/registration-page.module').then(m => m.RegistrationPagePageModule)
	},
	{
		path: '**',
		component: NotFoundComponent
	}
];
@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
