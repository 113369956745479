import { Component, Input, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormControl, FormGroup, Validators
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CustomValidator } from '../../_common/customvalidators';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ToastService } from '../../_services/toast.service';


@Component({
	selector: 'app-details',
	templateUrl: './details.component.html',
	styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
	myForm: FormGroup;
	files: any;
	public message: any;
	public fileToUpload: any
	companyLogoName: any;
	companyLogoPreview: any;
	companyLogoFile: any;
	public validateForm: FormGroup;
	@Input() brandData;
	public subscription = new Subscription();
	imgLogo: boolean = false;
	displayfileName: any;
	showLoader: boolean = false;
	brandDetails: any;
	public validation_messages = {
		brandEn: [
			{ type: 'required', message: 'brandManagementComponent.error.nameRequired' },
			{ type: 'invalidName', message: 'brandManagementComponent.error.invalidName' },
			{
				type: 'minlength',
				message: 'brandManagementComponent.error.nameMinLength'
			},
			{
				type: 'maxlength',
				message: 'brandManagementComponent.error.nameMaxLength'
			},
		],
		brandAr: [
			{ type: 'required', message: 'brandManagementComponent.error.nameArRequired' },
			{ type: 'invalidName', message: 'brandManagementComponent.error.nameArInvalid' },
			{ type: 'maxlength', message: 'brandManagementComponent.error.nameMaxLength' },
		],
	}
	constructor(private modalCtrl: ModalController,
		private translate: TranslateService,
		public commonService: CommonService,
		private fb: FormBuilder,
		private dataService: ApiService,
		private toastService: ToastService,) { }

	ngOnInit() {
		this.showLoader = true;;
		if (this.brandData) {
			let params = {
				brand_id: this.brandData
			}
			this.subscription.add(
				this.dataService.get(URLS.viewBrand, params).subscribe(res => {
					if (res.code == 200) {
						this.brandDetails = res['data'];
						this.validateForm = this.fb.group({
							brandEn: new FormControl(res['data']['brand_name_en'] ? res['data']['brand_name_en'] : null, {
								validators: Validators.compose([
									Validators.required,
									Validators.minLength(3),
									Validators.maxLength(50),
									CustomValidator.noWhiteSpaceValidator
								])
							}),
							brandAr: new FormControl(
								res['data']['brand_name_ar'], {
								validators: Validators.compose([
									Validators.required,
									Validators.maxLength(50),
									CustomValidator.arabicAddressValidator
								])
							}),

						});

					}

					if (res['data']['brand_logo_url']) {
						this.companyLogoPreview = res['data']['brand_logo_url'];
						this.companyLogoName = res['data']['brand_logo_url'].split('/').slice(-1)[0].replace('.webp', '')
					}
					this.showLoader = false;
				})
			)

		}
		else {
			this.validateForm = this.fb.group({
				brandEn: new FormControl(
					null, {
					validators: Validators.compose([
						Validators.required,
						Validators.minLength(3),
						Validators.maxLength(50),
						CustomValidator.nameValidator
					])
				}),
				brandAr: new FormControl(
					null, {
					validators: Validators.compose([
						Validators.required,
						Validators.maxLength(50),
						CustomValidator.arabicAddressValidator
					])
				}),

			});
			this.showLoader = false;
		}

	}



	dissmis() {
		this.modalCtrl.dismiss();
	}

	upload(files, fieldname?) {
		files.preventDefault();
		files.stopPropagation();
		this.imgLogo = false;
		this.displayfileName = files['target']['files']
		this.files = files['target']['files'][0].name;
		if (files && files['target'] && files['target']['files'] && files['target']['files'][0]) {
			const max_size = 5242880;
			if (files['target']['files'][0].size > max_size) {
				this.message =
					this.translate.instant('error.maxImage') + max_size / 1048576 + 'MB';
				this.toastService.displayToast(this.message, null, 'danger');
				this.files = null;
				this.fileToUpload = null;
				files.target.value = null;
				return false;
			}
			if ((files['target']['files'][0].type).includes("image/png") || (files['target']['files'][0].type).includes("image/jpeg") || (files['target']['files'][0].type).includes("image/jpg")) {
				this.message = null;
				const reader = new FileReader();
				reader.readAsDataURL(files['target']['files'][0]);
				reader.onload = (event: any) => {
					this.companyLogoPreview = event.target.result;
				};
				const obj = {
					fileUploaded: true
				}
				this.companyLogoFile = files['target']['files'][0];
				this.companyLogoName = files['target']['files'][0].name;

			}
			else {
				this.toastService.displayToast(this.translate.instant('error.brandImageErr'), null, 'danger');
				this.fileToUpload = null;
				files.target.value = null;
				this.files = null;
				return false;
			}
		}
	}

	get f() {
		return this.validateForm.controls;
	}

	saveBrand() {
		for (const i in this.f) {
			this.f[i].markAsDirty();
			this.f[i].updateValueAndValidity();
			this.f[i].markAsTouched();
		}

		if (!this.companyLogoPreview) {
			this.imgLogo = true;
			return;
		}
		if (this.validateForm.valid) {
			let checkDataExist = false;
			if (this.brandData) {
				if (this.f.brandEn.value != this.brandDetails['brand_name_en']) {
					checkDataExist = true;
				}
				if (this.f.brandAr.value != this.brandDetails['brand_name_ar']) {
					checkDataExist = true;
				}
				if (this.displayfileName) {
					checkDataExist = true;
				}
				const params = new FormData();
				params.append('brand_id', this.brandData);
				params.append('brand_name_en', this.f.brandEn.value);
				params.append('brand_name_ar', this.f.brandAr.value);
				if (this.displayfileName) {
					params.append('brand_logo_url', this.displayfileName[0]);
				}
				if (checkDataExist) {
					this.showLoader = true;
					this.subscription.add(
						this.dataService.put(URLS.updateBrand, params).subscribe(data => {
							if (data.code == 200) {
								this.modalCtrl.dismiss('isSuccess')
							}
							else {
								this.toastService.displayToast(data['message'], null, 'danger');
							}
							this.showLoader = false;
						}, error => {
							this.toastService.displayToast(error['message'], null, 'danger');
							this.showLoader = false;
						})
					)
				}
				else {
					this.toastService.displayToast(this.translate.instant('noDataChanged'), null, 'danger');
				}

			}
			else {
				const params = new FormData();
				params.append('brand_name_en', this.f.brandEn.value);
				params.append('brand_name_ar', this.f.brandAr.value);
				params.append('brand_logo_url', this.displayfileName[0]);
				this.showLoader = true;
				this.subscription.add(
					this.dataService.post(URLS.addBrand, params).subscribe(data => {
						if (data.code == 200) {
							this.modalCtrl.dismiss('isSuccess')
						}
						else {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
						this.showLoader = false;
					}, error => {
						this.toastService.displayToast(error['message'], null, 'danger');
						this.showLoader = false;
					})
				)
			}

		}
	}

	restrictSpecialChar(event) {
		var k;
		k = event.charCode;  //         k = event.keyCode;  (Both can be used)
		return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
	}

	remove(event, fieldname?) {
		event.preventDefault();
		event.stopPropagation();
		this.companyLogoFile = null;
		this.companyLogoName = null;
		this.companyLogoPreview = null;
	}

}
