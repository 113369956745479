import { Component, Input, OnInit } from '@angular/core';
// import { IonSlides } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';
import { ToastService } from '../../_services/toast.service';
@Component({
	selector: 'app-product-preview-detail',
	templateUrl: './product-preview-detail.component.html',
	styleUrls: ['./product-preview-detail.component.scss'],
})
export class ProductPreviewDetailComponent implements OnInit {
	// @ViewChild("Productslides", { static: false }) relatedslider: IonSlides;

	@Input() product_detail: any;
	public zoomButtonClick: boolean = false;
	public slideOpts = {
		initialSlide: 0,
		speed: 400,
		slidesPerView: 1,
	};
	public sliderPager: boolean = false;
	public isChecked: any = 0;
	public productValue = 0;
	public noImageData: boolean = false;
	// @ViewChild("slides", { static: false }) slider: IonSlides;
	public iontabs: any = 0;
	public product_images: any = [];
	public product_name: any;
	public supplier_name: any;
	public product_price: any;
	public product_unit: any;
	public reviews: any = [];
	public loadMore: boolean = false;
	activeSection = 'section1';
	public average_rating: any = '0';
	public total_ratings: any;
	public sizeOptions: any = [];
	public product_description: any;
	public brand_name: any;
	public product_id: any;
	public imagesArray = [];
	public configData = [];
	public step: any;
	public subProduct: any;
	productSpecification = [];
	showSpecification = false;
	noDocument = false;
	public productDesignImages = [];
	public subscription = new Subscription();
	public configDataOptions: any;
	public selectedConfigData = 0;
	public selectedConfigValue: any;
	public selectedId: any;
	public selected_subproduct: any;
	public selectedConfigurationId: any = [];
	public related_products = [];
	public relatedSlideOptions = {
		initialSlide: 0,
		speed: 200,
		spaceBetween: 40,
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		},
		breakpoints: {
			320: {
				slidesPerView: 2.3,
			},
			488: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 3,
			},
			850: {
				slidesPerView: 3.5,
			},
			1100: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 5,
			},
			1600: {
				slidesPerView: 5,
			},
			1800: {
				slidesPerView: 5,
			},
			1920: {
				slidesPerView: 5,
			},
		},
	}
	reviewparams = {
		page: 1,
		size: 10,
	}

	public lastSlide: boolean = false;
	public slideIndex: any;
	public firstSlide: boolean = true;
	public sliderEnd: any;

	constructor(
		public commonService: CommonService,
		private dataService: ApiService,
		private toastService: ToastService,
		private modalService: ModalService
	) { }

	ngOnInit() {
		if (this.product_detail) {
			this.initializeProductDetails();
		} else {
			this.getPreviewData(this.product_id)
		}
	}

	designChanged(event) {
		this.isChecked = +event.detail.value;
	}

	attributeChange(data) {
		// code required
		// if (data.en.price_per_unit) {
		//     this.product_price = data.en.price_per_unit;
		// }
	}

	designChecked(data, array) {
		array.forEach((element) => {
			if (element.configuration_value_id == data.configuration_value_id) {
				element.select = true;
			} else {
				element.select = false;
			}
		});
		this.selectedConfigurationId = [];
		this.imagesArray = [];
		this.configData.forEach(element => {
			element.value_array.forEach(valueElement => {
				if (valueElement.select === true) {
					this.selectedConfigurationId.push(valueElement.configuration_value_id)
				}
			});
		});

		this.subProductSelection();

	}

	subProductSelection() {
		if (this.subProduct && this.subProduct.length) {
			this.subProduct.forEach(element => {
				let idExist;
				let mainIdExist;
				this.selectedConfigurationId.forEach(element3 => {
					if (element.product_configurations && element.product_configurations.length) {
						idExist = this.configExitst(element.product_configurations, element3);
					}
					if (idExist == false) {
						mainIdExist = false;
					}
				});

				if (mainIdExist != false) {
					this.selected_subproduct = element;
					this.productValue = 1;
					let imagesArray = this.selected_subproduct['product_images'];
					this.imagesArray = [];
					if (imagesArray && imagesArray.length) {
						imagesArray.forEach(element => {
							if (element['image_type'] == "web") {
								if (element.is_cover_images) {
									this.imagesArray.unshift(element);
								} else {
									this.imagesArray.push(element);
								}
							}
						});
					}
					this.product_name = this.commonService.domDirectionLTR ? this.selected_subproduct.product_name_en : this.selected_subproduct.product_name_ar;
					this.product_price = this.selected_subproduct.price_per_unit;
				}
			});
		}
	}

	configExitst(arr, value) {
		return arr.some(function (el) {
			return el.configuration_value_id === value;
		});
	}

	getPreviewData(id) {
		const params = {
			product_id: id
		}
		this.subscription.add(
			this.dataService.get(URLS.getPreview, params).subscribe(data => {
				if (data['code'] == 200) {
					this.product_detail = data['data'];
					this.initializeProductDetails();
				}
			})
		)
	}

	initializeProductDetails() {
		let productDetails = this.product_detail;
		if (productDetails.documents && productDetails.documents.length) {
			productDetails.documents.forEach(element => {
				element.isOpen = false
			});
			let obj = productDetails.documents.find(element => element.documents && element.documents.length)
			if (obj) {
				this.noDocument = false;
			} else {
				this.noDocument = true;
			}
		}
		this.reviews = productDetails['product_reviews'] && productDetails['product_reviews'].length ? productDetails['product_reviews'] : []
		this.product_name = this.commonService.domDirectionLTR ? productDetails['product_name_en'] : productDetails['product_name_ar'];
		this.average_rating = productDetails['average_rating'] ? productDetails['average_rating'] : '0';
		this.total_ratings = "0";
		this.product_description = this.commonService.domDirectionLTR ? productDetails['product_desc_en'] : productDetails['product_desc_ar'];
		this.brand_name = this.commonService.domDirectionLTR ? productDetails['brand_name_en'] : productDetails['brand_name_ar'];
		this.supplier_name = this.commonService.domDirectionLTR ? productDetails['supplier_name_en'] : productDetails['supplier_name_ar'];
		this.imagesArray = productDetails['default_web_images'];
		this.product_price = productDetails['price_per_unit'];
		this.product_unit = this.commonService.domDirectionLTR ? productDetails['unit_name_en'] : productDetails['unit_name_ar'];
		this.showSpecification = false;
		this.productSpecification = productDetails['common_configurations'];
		let main_config: any = [];
		let value_array: any = [];
		if (productDetails['sub_products'] && productDetails['sub_products'].length) {
			productDetails['sub_products'].forEach(element => {
				if (element.product_configurations && element.product_configurations.length) {
					element.product_configurations.forEach(element2 => {
						value_array.push(JSON.parse(JSON.stringify(element2)));
					});
				}
			});
		}

		let unique: any = [...new Map(value_array.map(item => [item['configuration_value_id'], item])).values()];

		unique.forEach(element => {
			var isPresent = main_config.some(function (el) { return el.configuration_id === element.configuration_id });
			if (!isPresent) {

				const lookup = unique.reduce((a, e) => {
					a[e.configuration_id] = ++a[e.configuration_id] || 0;
					return a;
				}, {});
				main_config.push({
					configuration_id: element.configuration_id,
					configuration_name_ar: element.configuration_name_ar,
					configuration_name_en: element.configuration_name_en,
					value_array: this.getConfigurationValue(unique, element.configuration_id),
					selected_value: this.getConfigurationValue(unique, element.configuration_id)[0].configuration_value_en,
					selected_id: this.getConfigurationValue(unique, element.configuration_id)[0].configuration_value_id,
					attributeValue: "0",
				});
			}
		});

		this.configData = main_config;
		this.subProduct = productDetails['sub_products'];
		main_config.forEach((configElement) => {
			this.selectedConfigurationId.push(configElement.value_array[0].configuration_value_id)
			configElement.value_array.forEach((valueElement, index) => {
				if (index === 0) {
					valueElement['select'] = true;
				} else {
					valueElement['select'] = false;
				}
			});
		});
		this.subProductSelection();
		if (this.productSpecification && this.productSpecification.length) {
			this.productSpecification.forEach(element => {
				if (!this.showSpecification) {
					if (element.configuration_value_id || element.configuration_value) {
						this.showSpecification = true;
					}
				}
			});
		}
		if (this.product_detail.related_products && this.product_detail.related_products.length) {
			this.product_detail.related_products.forEach(element => {
				let obj = {
					product_description: element.product_name_en ? element.product_name_en : "",
					product_name: element.product_name_en ? element.product_name_en : "",
					product_name_ar: element.product_name_ar ? element.product_name_ar : "",
					brand_name_en: element.brand_name_en ? element.brand_name_en : "",
					brand_name_ar: element.brand_name_ar ? element.brand_name_ar : '',
					product_currency: "SR ",
					product_price: element.price_per_unit ? element.price_per_unit : "",
					price_per_unit: element.price_per_unit ? element.price_per_unit : "",
					final_price: element.final_price ? element.final_price : "",
					product_unit: this.commonService.domDirectionLTR ? element.unit_name_en : element.unit_name_ar,
					product_unit_arb: element.unit_name_ar,
					product_id: element.product_id,
					main_product_ref_id: element.main_product_ref_id,
					sold_quantity: element.sold_quantity ? element.sold_quantity : null,
					is_discount_applied: element.is_discount_applied ? element.is_discount_applied : null,
					product_sku: element.product_sku ? element.product_sku : null,
					discount_type: element.discount_type ? element.discount_type : null,
					discount_value: element.discount_value ? element.discount_value : null,
					sub_product_id: element.sub_product_id ? element.sub_product_id : null,
					product_images_web: element.product_images_web,
					product_images_mobile: element.product_images_mobile

				}

				this.related_products.push(obj);
			});
		}

	}

	getConfigurationValue(array, id) {
		let values = [];
		array.forEach(element => {
			if (element.configuration_id == id) {
				delete element['configuration_name_en'];
				delete element['configuration_name_ar'];
				values.push(element);
			}
		});
		return values;
	}

	accordionGroupChange(event) {
		const selectedValue = event.detail.value;
		this.product_detail.documents.forEach((element) => {
			if (selectedValue.includes(element.document_type_id.toString())) {
				element.isOpen = true;
			} else {
				element.isOpen = false;
			}
		});
	}

	// async segmentChanged(event?) {
	//     await this.slider.slideTo(this.iontabs);
	// }

	// async slideChanged() {
	//     this.iontabs = await this.slider.getActiveIndex();
	//     const slider = await this.slider.getSwiper();
	//     const zoom = slider.zoom;
	//     this.zoomButtonClick = false;
	//     zoom.out();
	// }

	// async zoom() {
	//     this.zoomButtonClick = !this.zoomButtonClick
	//     const slider = await this.slider.getSwiper();
	//     const zoom = slider.zoom;
	//     if (this.zoomButtonClick) {
	//         zoom.in();
	//     } else {
	//         zoom.out();
	//     }

	// }

	logRatingChange(data) { }

	closeModal() {
		this.modalService.dismissModal()
	}


	// slideToNext() {
	//     this.relatedslider.slideNext();
	// }

	// slideToPrev() {
	//     this.relatedslider.slidePrev();
	// }

	// async relatedslideChanged() {
	//     this.slideIndex = await this.relatedslider.getActiveIndex();
	//     this.sliderEnd = await this.relatedslider.isEnd();
	//     if (this.slideIndex === 0) {
	//         this.firstSlide = true;
	//         this.lastSlide = false;
	//     } else if (this.slideIndex > 0) {
	//         this.firstSlide = false;
	//         this.lastSlide = false;
	//     }
	// }



}
