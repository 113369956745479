import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CustomValidator } from '../../_common/customvalidators';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';
import { ToastService } from '../../_services/toast.service';

@Component({
	selector: 'app-add-featured-product',
	templateUrl: './add-featured-product.component.html',
	styleUrls: ['./add-featured-product.component.scss'],
})
export class AddFeaturedProductComponent implements OnInit {
	public addProductForm: FormGroup;
	public subscription = new Subscription();
	endDate: any;
	startDate: any;
	isEdit = false;
	editData: any;
	currencySymbol: any = '';
	selectedProducts = [];
	pageno = 1;
	pagesize = 10;
	selectInterface = { cssClass: 'custom-select-dd' };
	public totalItems = 0;
	public addFpItemsPerPage = 10;
	public addFpCurrentPage = 1;
	public validation_messages = {
		product_start_date_input: [
			{ type: 'required', message: 'featuresProductsComponent.errors.startDateRequired' },
		],
		product_end_date_input: [
			{ type: 'required', message: 'featuresProductsComponent.errors.endDateRequired' },
		],
		commission: [
			{ type: 'required', message: 'featuresProductsComponent.errors.commissionRequired' },
			{ type: 'min', message: 'featuresProductsComponent.errors.commissionMin' },
			{ type: 'max', message: 'featuresProductsComponent.errors.commissionMax' },
			{ type: 'invalidNumber', message: 'featuresProductsComponent.errors.commissionInvalid' },

		],
	}
	minDate: any;
	todaysDate = new Date();

	productList = [];

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private dataService: ApiService,
		public commonService: CommonService,
		private modalService: ModalService,
		private modalCtrl: ModalController,
		private toastService: ToastService,
		private datePipe: DatePipe
	) { }

	ngOnInit() {
		this.addProductForm = this.initializeForm();
		this.minDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');

		let params = {
			'page': 1,
			'size': 10,
			status: 'approved'
		}
		if (this.editData) {
			this.addProductForm = this.initializeForm(this.editData);
		} else {
			this.getProductList(params)
		}
	}

	ionViewWillEnter() {
		this.subscription = new Subscription();
	}

	onDateChange(datetime) {
		setTimeout(() => {
			datetime.confirm(true);
		}, 300)
	}

	initializeForm(data?) {
		if (data) {
			this.startDate = data.start_date;
			this.endDate = data.end_date;
		}
		return this.formBuilder.group({
			product_start_date: new FormControl({ value: data && data.start_date ? data.start_date : null }, {
				validators: Validators.compose([Validators.required]),
			}),
			product_start_date_input: new FormControl(data && data.start_date ? this.datePipe.transform(data.start_date, 'YYYY-MM-dd') : null, {
				validators: Validators.compose([Validators.required]),
			}),
			product_end_date: new FormControl({ value: data && data.end_date ? data.end_date : null }, {
				validators: Validators.compose([Validators.required]),
			}),
			product_end_date_input: new FormControl(data && data.end_date ? this.datePipe.transform(data.end_date, 'YYYY-MM-dd') : null, {
				validators: Validators.compose([Validators.required]),
			}),
			commission: new FormControl(data && data.commission_percent ? +data.commission_percent : null, {
				validators: Validators.compose([Validators.required, Validators.min(0), Validators.max(99), CustomValidator.decimalNumberValidator]),
			}),
		});
	}

	onSubmit() {
		this.addProductForm.controls['product_start_date'].setValue(this.startDate);
		this.addProductForm.controls['product_start_date_input'].setValue(this.startDate);
		this.addProductForm.controls['product_end_date'].setValue(this.endDate);
		this.addProductForm.controls['product_end_date_input'].setValue(this.endDate);

		for (const i in this.addProductForm.controls) {
			this.addProductForm.controls[i].markAsDirty();
			this.addProductForm.controls[i].updateValueAndValidity();
			this.addProductForm.controls[i].markAsTouched();
		}
		if (this.addProductForm.invalid) {
			return
		}
		if (!this.selectedProducts.length && !this.isEdit) {
			this.toastService.displayToast('error.minOneProduct', null, 'danger')
			return
		}
		if ((this.startDate && !this.endDate) || (!this.startDate && this.endDate)) {
			if (!this.startDate) {
				this.toastService.displayToast('error.selectStartDate', null, 'danger');
			}
			if (!this.endDate) {
				this.toastService.displayToast('error.selectEndDate', null, 'danger');
			}
			return
		}
		if (this.startDate && this.endDate && (this.startDate > this.endDate)) {
			this.toastService.displayToast('error.startDateGreater', null, 'danger');
			return
		}
		if (this.addProductForm.value.commission > 100) {
			this.toastService.displayToast('error.commissionPercent', null, 'danger');
			return
		}
		if (this.isEdit) {
			const params = {
				"request_id": this.editData.request_id,
				"commission_request_percentage": +this.addProductForm.value.commission,
				"start_date": this.datePipe.transform(this.startDate, 'yyyy-MM-dd'),
				"end_date": this.datePipe.transform(this.endDate, 'yyyy-MM-dd')
			}
			this.subscription.add(
				this.dataService.post(URLS.updateFeaturedProduct, params).subscribe(data => {
					if (data && data['code'] == 200) {
						this.close(true);
						this.addProductForm.reset();
					} else {
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				}, error => {
					this.toastService.displayToast(error['message'], null, 'danger');
				})
			)
		} else {
			const params = {
				"product_id": this.selectedProducts,
				"commission_request_percentage": +this.addProductForm.value.commission,
				"start_date": this.datePipe.transform(this.startDate, 'yyyy-MM-dd'),
				"end_date": this.datePipe.transform(this.endDate, 'yyyy-MM-dd'),
			}
			this.subscription.add(
				this.dataService.post(URLS.addFeaturedProduct, params).subscribe(data => {
					if (data && data['code'] == 200) {
						this.close(true);
					} else {
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				}, error => {
					this.toastService.displayToast(error['message'], null, 'danger');
				})
			)
		}
	}

	prev() {
		// this.addProductForm.reset();
	}

	ionViewWillLeave() {
		this.subscription.unsubscribe();
	}

	selectCheckbox(event: any, id) {
		if (event.detail.checked) {
			if (!this.selectedProducts.includes(id)) {
				this.selectedProducts.push(id);
			}
		} else {
			let index = this.selectedProducts.indexOf(id);
			if (index >= 0) {
				this.selectedProducts.splice(index, 1);
			}
		}
	}

	close(updated?) {
		this.modalCtrl.dismiss(updated);
	}

	onChange(event): void {
		this.addFpCurrentPage = event;
		this.pageno = event;
		let params = {
			page: event,
			size: this.pagesize,
			status: 'approved'
		}
		this.getProductList(params, false);
	}

	getProductList(params?, clearFilter?: boolean) {
		this.subscription.add(
			this.dataService.get(URLS.productList, params).subscribe(data => {
				if (data['code'] == 200) {
					this.productList = [];
					if (data['data'] && data['data']['result'] && data['data']['result'].length) {
						let list = data['data']['result'];
						list.forEach((element, index) => {
							let obj = {
								product_id: element.product_id,
								image: element.images && element.images.length ? element.images[0].image_url : './assets/images/empty-img.png',
								name: this.commonService.domDirectionLTR ? element.product_name_en ? element.product_name_en : '-' : element.product_name_ar ? element.product_name_ar : '-',
								qty: element.total_quantity ? element.total_quantity : 0,
								qty_sold: element.quantity_sold ? element.quantity_sold : 0,
								discount: element.discount ? element.discount : 0,
								sku: element.product_sku ? element.product_sku : '',
								price: element.price_per_unit ? element.price_per_unit : 0,
								discounted_price: element.discounted_price ? element.discounted_price : 0,
								updated_time: element.updated_at ? element.updated_at : '',
								created_time: element.created_at ? element.created_at : '',
								product_status: element.approval_status ? element.approval_status : '',//pending
								category: this.commonService.domDirectionLTR ? element.category_name_en ? element.category_name_en : '-' : element.category_name_ar ? element.category_name_ar : '-',
								subcategory: this.commonService.domDirectionLTR ? element.sub_class_name_en ? element.sub_class_name_en : '-' : element.sub_class_name_ar ? element.sub_class_name_ar : '-',
							}
							if (this.selectedProducts.includes(element.product_id)) {
								obj['isChecked'] = true;
							}
							this.productList.push(obj)
						});
						if (!this.productList.length) {
							this.close();
						}
					}
					this.totalItems = data['data'] && data['data']['total_count'] ? +data['data']['total_count'] : 1;
				} else {
					this.close();
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.close();
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	numberOnlyValidation(event: any) {
		const pattern = /[0-9]/;
		let inputChar = String.fromCharCode(event.charCode);

		if (!pattern.test(inputChar)) {
			// invalid character, prevent input
			event.preventDefault();
		}
	}
}
