// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td,
th {
  min-width: 160px;
  text-align: left;
}

th:nth-child(1),
td:nth-child(1) {
  min-width: 100px;
}

th:nth-child(5),
td:nth-child(5) {
  min-width: 110px;
}

th:nth-child(10),
td:nth-child(10),
th:nth-child(14),
td:nth-child(14) {
  min-width: 190px;
}

td {
  cursor: pointer;
}

.status-col {
  padding: 0.75rem 0.5625rem !important;
}

.column-heading {
  font-size: 0.85rem !important;
}

.status-label {
  width: 100%;
  padding-bottom: 0.625rem;
  font-weight: bold;
  text-transform: capitalize !important;
  color: var(--ion-color-primary-black);
}

.filter-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  column-gap: 0.625rem;
  row-gap: 0.625rem;
}

.filter-section {
  flex-wrap: wrap;
  justify-content: unset !important;
  column-gap: 1.25rem;
}

.from-date-div,
.to-date-div {
  width: unset;
  margin-bottom: 0.9375rem;
}

.from-date-class,
.to-date-class {
  max-width: 190px;
}

.to-date-class {
  margin-top: 2rem;
}

.status-container {
  column-gap: 20px;
}

.close-label {
  color: var(--ion-color-primary-black) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/refund/refund.component.scss"],"names":[],"mappings":"AAAA;;EAEI,gBAAA;EACA,gBAAA;AACJ;;AAEA;;EAEI,gBAAA;AACJ;;AAEA;;EAEI,gBAAA;AACJ;;AAEA;;;;EAII,gBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,qCAAA;AACJ;;AAEA;EACI,6BAAA;AACJ;;AAEA;EACI,WAAA;EACA,wBAAA;EACA,iBAAA;EACA,qCAAA;EACA,qCAAA;AACJ;;AAEA;EACI,WAAA;EACA,aAAA;EACA,yBAAA;EACA,oBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,eAAA;EACA,iCAAA;EACA,mBAAA;AACJ;;AAEA;;EAEI,YAAA;EACA,wBAAA;AACJ;;AAEA;;EAEI,gBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,gDAAA;AACJ","sourcesContent":["td,\nth {\n    min-width: 160px;\n    text-align: left;\n}\n\nth:nth-child(1),\ntd:nth-child(1) {\n    min-width: 100px;\n}\n\nth:nth-child(5),\ntd:nth-child(5) {\n    min-width: 110px;\n}\n\nth:nth-child(10),\ntd:nth-child(10),\nth:nth-child(14),\ntd:nth-child(14) {\n    min-width: 190px;\n}\n\ntd {\n    cursor: pointer;\n}\n\n.status-col {\n    padding: 0.75rem 0.5625rem !important;\n}\n\n.column-heading {\n    font-size: 0.85rem !important;\n}\n\n.status-label {\n    width: 100%;\n    padding-bottom: 0.625rem;\n    font-weight: bold;\n    text-transform: capitalize !important;\n    color: var(--ion-color-primary-black);\n}\n\n.filter-buttons {\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    column-gap: 0.625rem;\n    row-gap: 0.625rem;\n}\n\n.filter-section {\n    flex-wrap: wrap;\n    justify-content: unset !important;\n    column-gap: 1.25rem;\n}\n\n.from-date-div,\n.to-date-div {\n    width: unset;\n    margin-bottom: 0.9375rem;\n}\n\n.from-date-class,\n.to-date-class {\n    max-width: 190px;\n}\n\n.to-date-class {\n    margin-top: 2rem;\n}\n\n.status-container {\n    column-gap: 20px;\n}\n\n.close-label {\n    color: var(--ion-color-primary-black) !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
