// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container .login-header {
  font-weight: 600;
  font-size: 2rem;
  line-height: 110%;
  color: #0A3040;
  padding-bottom: 1.875rem;
}
.login-container .login-desktop-header {
  font-weight: 600;
  font-size: 2.125rem;
  line-height: 4.375rem;
  color: #0A3040;
  padding-bottom: 0.75rem;
}
.login-container .email {
  padding-bottom: 0.625rem;
}
.login-container .forgot-password {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  color: #456371;
}
.login-container .login-bottom-container {
  border-top: 1px solid #D5DADC;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 3.125rem;
}

.modal-close {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.login-button button {
  background: var(--ion-color-primary);
  width: 114px;
  height: 46px;
  position: relative;
  border-radius: 2px;
  font-weight: 600;
  font-size: 0.9375rem;
  color: #fff;
}

.footer-md::before {
  background-image: none;
}

.spinner-btn {
  color: var(--ion-color-primary-white);
  position: absolute;
  right: 5rem;
  top: 0.625rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/forgot-password/forgot-password.component.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,wBAAA;AAAR;AAGI;EACI,gBAAA;EACA,mBAAA;EACA,qBAAA;EACA,cAAA;EACA,uBAAA;AADR;AAII;EACI,wBAAA;AAFR;AAKI;EACI,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;AAHR;AAMI;EACI,6BAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,qBAAA;AAJR;;AAQA;EACI,kBAAA;EACA,QAAA;EACA,eAAA;AALJ;;AASI;EACI,oCAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBAAA;EACA,WAAA;AANR;;AAUA;EACI,sBAAA;AAPJ;;AAUA;EACI,qCAAA;EACA,kBAAA;EACA,WAAA;EACA,aAAA;AAPJ","sourcesContent":[".login-container {\n    .login-header {\n        font-weight: 600;\n        font-size: 2rem;\n        line-height: 110%;\n        color: #0A3040;\n        padding-bottom: 1.875rem;\n    }\n\n    .login-desktop-header {\n        font-weight: 600;\n        font-size: 2.125rem;\n        line-height: 4.375rem;\n        color: #0A3040;\n        padding-bottom: 0.75rem;\n    }\n\n    .email {\n        padding-bottom: 0.625rem;\n    }\n\n    .forgot-password {\n        font-weight: 400;\n        font-size: 0.875rem;\n        line-height: 150%;\n        color: #456371;\n    }\n\n    .login-bottom-container {\n        border-top: 1px solid #D5DADC;\n        display: flex;\n        align-items: center;\n        justify-content: flex-end;\n        padding-top: 3.125rem;\n    }\n}\n\n.modal-close {\n    position: absolute;\n    right: 0;\n    cursor: pointer;\n}\n\n.login-button {\n    button {\n        background: var(--ion-color-primary);\n        width: 114px;\n        height: 46px;\n        position: relative;\n        border-radius: 2px;\n        font-weight: 600;\n        font-size: 0.9375rem;\n        color: #fff;\n    }\n}\n\n.footer-md::before {\n    background-image: none;\n}\n\n.spinner-btn {\n    color: var(--ion-color-primary-white);\n    position: absolute;\n    right: 5rem;\n    top: 0.625rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
