import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CommonService } from './common.service';

@Injectable({
	providedIn: 'root'
})
export class GetLatLongService {


	constructor(public platform: Platform,
		// public nativeGeocoder: NativeGeocoder,
		public commonService: CommonService) {

	}

	// async getLatLongLocation(address) {
	//     let geocoder = await new google.maps.Geocoder();

	//     await geocoder.geocode({ address: address }, (result, error) => {
	//         this.commonService.localStorageSet("latitude", result[0].geometry.location.lat())
	//         this.commonService.localStorageSet("longitude", result[0].geometry.location.lng())

	//     });
	// }
}
