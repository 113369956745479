import { DOCUMENT } from '@angular/common';
import {
	Component,
	ElementRef,
	Inject,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { IonInput, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CustomValidator } from '../../_common/customvalidators';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';
import { ToastService } from '../../_services/toast.service';
import { ChangePasswordComponent } from '../../components/change-password/change-password.component';

@Component({
	selector: 'app-my-profile',
	templateUrl: './my-profile.component.html',
	styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
	@ViewChild('companyLogoNameElem') companyLogoNameElem: ElementRef;
	@ViewChild('companyCrNoElem') companyCrNoElem: ElementRef;
	@ViewChild('vatCertificateNoElem') vatCertificateNoElem: ElementRef;
	@ViewChild('nationalAddressFileElem') nationalAddressFileElem: ElementRef;
	@ViewChild('emailInput', { static: false }) emailInput!: IonInput;
	@ViewChild('phoneInput', { static: false }) phoneInput!: IonInput;

	public profileForm: FormGroup;
	public companyAddressForm: FormGroup;
	public subscription = new Subscription();
	showPassword = false;
	passwordText = 'changePassword';
	passwordType = 'password';
	confirmPasswordType = 'password';

	public message: any;
	public fileToUpload: any;

	isCompanyCRNoFile = false;
	isVatCertificateNoFile = false;
	isBrandLogoFile = false;
	isCompanyLogoFile = false;
	nationalAddressFile: any;
	isNationalAddressFile = false;
	nationalAddressFileName: any;
	companyLogoName: any;
	brandLogoName: any;
	companyLogoPreview: any;
	brandLogoPreview: any;
	companyCRNoName: any;
	vatCertificateNoName: any;
	companyLogoFile: any;
	brandLogoFile: any;
	companyCRNoFile: any;
	vatCertificateNoFile: any;
	files: any;
	editData: any;
	countryCode = '966';
	isCountryListOpen = false;
	isDropdownValue = false;
	isLoading = false;
	isEmailDisabled = true;
	isPhoneDisabled = true;
	regionList = [];
	countryList = [];
	cityList = [];
	locationParams = [];

	addressDD = {
		0: {
			region: [],
			city: [],
		},
	};
	public validation_messages = {
		name: [
			{ type: 'required', message: 'MyProfileComponent.nameRequired' },
			{ type: 'invalidString', message: 'MyProfileComponent.invalidName' },
		],
		nameAr: [
			{ type: 'required', message: 'MyProfileComponent.nameArabicRequired' },
			{ type: 'invalidName', message: 'MyProfileComponent.invalidNameArabic' },
		],
		email: [
			{ type: 'required', message: 'MyProfileComponent.emailRequired' },
			{ type: 'invalidEmail', message: 'MyProfileComponent.invalidEmail' },
		],
		phone_no: [
			{ type: 'required', message: 'MyProfileComponent.phoneNoRequired' },
			{ type: 'invalidNumber', message: 'MyProfileComponent.invalidPhoneNo' },
		],
		contact_person_fullname: [
			{
				type: 'required',
				message: 'MyProfileComponent.contactPersonFullnameRequired',
			},
			{
				type: 'invalidName',
				message: 'MyProfileComponent.invalidContactPersonFullname',
			},
		],
		contact_person_mobile_no: [
			{
				type: 'required',
				message: 'MyProfileComponent.contactPersonMobileNoRequired',
			},
			{
				type: 'invalidNumber',
				message: 'MyProfileComponent.invalidContactPersonMobileNo',
			},
		],
		company_legal_name: [
			{
				type: 'required',
				message: 'MyProfileComponent.companyLegalNameRequired',
			},
			{
				type: 'invalidName',
				message: 'MyProfileComponent.invalidCompanyLegalName',
			},
		],
		company_legal_name_ar: [
			{
				type: 'required',
				message: 'MyProfileComponent.companyLegalNameArabicRequired',
			},
			{
				type: 'invalidName',
				message: 'MyProfileComponent.invalidcompanyLegalNameArabic',
			},
		],
		main_brand: [
			{ type: 'required', message: 'MyProfileComponent.mainBrandRequired' },
			{ type: 'invalidName', message: 'MyProfileComponent.invalidMainBrand' },
		],
		main_brand_ar: [
			{
				type: 'required',
				message: 'MyProfileComponent.mainbrandArabicRequired',
			},
			{
				type: 'invalidName',
				message: 'MyProfileComponent.invalidMainBrandArabic',
			},
		],
		company_cr_number: [
			{
				type: 'required',
				message: 'MyProfileComponent.companyCrNumberRequired',
			},
			{ type: 'invalidNumber', message: 'companyDetailsComponent.invalidCrNo' },
			{
				type: 'invalidName',
				message: 'MyProfileComponent.invalidcompanyCrNumber',
			},
		],
		vat_certificate_number: [
			{
				type: 'required',
				message: 'MyProfileComponent.VatCertificateNumberRequired',
			},
			{
				type: 'invalidNumber',
				message: 'companyDetailsComponent.invalidVatNo',
			},
			{
				type: 'invalidName',
				message: 'MyProfileComponent.invalidVatCertificateNumber',
			},
		],
		national_address_file: [
			{
				type: 'required',
				message: 'MyProfileComponent.nationalAddressFileError',
			},
		],
		company_cr_file: [
			{ type: 'required', message: 'MyProfileComponent.companyCrFileRequired' },
		],
		vat_certificate_file: [
			{
				type: 'required',
				message: 'MyProfileComponent.VatCertificateFileRequired',
			},
		],
		iso_country_code: [
			{
				type: 'required',
				message: 'MyProfileComponent.isoCountryCodeRequired',
			},
		],
		iban_check_digits: [
			{
				type: 'required',
				message: 'MyProfileComponent.ibanCheckDigitsRequired',
			},
		],
		iban: [{ type: 'required', message: 'MyProfileComponent.bbanRequired' }],
		sepa_member: [
			{ type: 'required', message: 'MyProfileComponent.sepaMemberRequired' },
		],
		hq_address_english: [
			{
				type: 'required',
				message: 'MyProfileComponent.hqAddressEnglishRequired',
			},
			{ type: 'invalidString', message: 'MyProfileComponent.invalidHqAddressEnglish' },

		],
		hq_address_arabic: [
			{
				type: 'required',
				message: 'MyProfileComponent.hqAddressArabicRequired',
			},
			{
				type: 'invalidName',
				message: 'MyProfileComponent.invalidHqAddressArabic',
			},
			{ type: 'invalidString', message: 'MyProfileComponent.invalidHqAddressArabic' },

		],
		country_en: [
			{ type: 'required', message: 'MyProfileComponent.countryRequired' },
		],
		country_ar: [
			{ type: 'required', message: 'MyProfileComponent.countryRequired' },
		],
		region_en: [
			{ type: 'required', message: 'MyProfileComponent.stateRequired' },
		],
		region_ar: [
			{ type: 'required', message: 'MyProfileComponent.stateRequired' },
		],
		city_en: [{ type: 'required', message: 'MyProfileComponent.cityRequired' }],
		city_ar: [{ type: 'required', message: 'MyProfileComponent.cityRequired' }],
		zip_en: [
			{ type: 'required', message: 'MyProfileComponent.zipRequired' },
			{ type: 'minlength', message: 'MyProfileComponent.zipMinlength' },
			{ type: 'maxlength', message: 'MyProfileComponent.zipMaxlength' },
		],
		land_line: [
			{ type: 'required', message: 'MyProfileComponent.landLineRequired' },
			{ type: 'invalidNumber', message: 'MyProfileComponent.invalidPhoneNo' },
		],
		bank_name_english: [
			{ type: 'required', message: 'MyProfileComponent.bankNameRequired' },
			{ type: 'invalidName', message: 'MyProfileComponent.invalidBankName' },
		],
		bank_name_arabic: [
			{
				type: 'required',
				message: 'MyProfileComponent.bankNameArabicRequired',
			},
			{
				type: 'invalidName',
				message: 'MyProfileComponent.invalidBankNameArabic',
			},
		],
		country_en_payment: [
			{ type: 'required', message: 'MyProfileComponent.countryRequired' },
		],
		country_ar_payment: [
			{ type: 'required', message: 'MyProfileComponent.countryArabicRequired' },
		],
		branch_code: [
			{ type: 'required', message: 'MyProfileComponent.branchCodeRequired' },
			{ type: 'invalidName', message: 'MyProfileComponent.branchCodeInvalid' },
			{ type: 'minlength', message: 'MyProfileComponent.branchCodeMinLength' },
			{ type: 'maxlength', message: 'MyProfileComponent.branchCodeMaxLength' },
		],
		bank_account_name_english: [
			{
				type: 'required',
				message: 'MyProfileComponent.bankAccountNameRequired',
			},
			{
				type: 'invalidName',
				message: 'MyProfileComponent.bankAccountNameInvalid',
			},
			{
				type: 'minlength',
				message: 'MyProfileComponent.bankAccountNameMinLength',
			},
			{
				type: 'maxlength',
				message: 'MyProfileComponent.bankAccountNameMaxLength',
			},
		],
		bank_account_name_arabic: [
			{
				type: 'required',
				message: 'MyProfileComponent.bankAccountNameArRequired',
			},
			{
				type: 'invalidName',
				message: 'MyProfileComponent.bankAccountNameArInvalid',
			},
		],
		bank_account_iban: [
			{
				type: 'required',
				message: 'MyProfileComponent.bankAccountIbanRequired',
			},
			{
				type: 'invalidName',
				message: 'MyProfileComponent.bankAccountIbanInvalid',
			},
			{
				type: 'invalidString',
				message: 'MyProfileComponent.bankAccountIbanInvalid',
			},
			{
				type: 'maxlength',
				message: 'MyProfileComponent.bankAccountIbanMaxLength',
			},
		],
		swift_code: [
			{ type: 'required', message: 'MyProfileComponent.swiftCodeRequired' },
			{ type: 'invalidName', message: 'MyProfileComponent.swiftCodeInvalid' },
			{ type: 'maxlength', message: 'MyProfileComponent.swiftCodeMaxLength' },
			{ type: 'minlength', message: 'MyProfileComponent.swiftCodeMinLength' },
		],
	};

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		public commonService: CommonService,
		private modalService: ModalService,
		private toastService: ToastService,
		private modalCtrl: ModalController,
		private dataService: ApiService,
		@Inject(DOCUMENT) private document: Document
	) { }

	ngOnInit() {
		const element = this.document.querySelector('#scrollId');
		if (element) {
			element.scrollIntoView();
		}
		this.subscription = new Subscription();
		this.profileForm = this.initializeForm();
		this.companyAddressForm = this.companyAddress();
		this.getCountryList();
		this.getRegions(null, 0);
		this.getCityList(null, 0);
		setTimeout(() => {
			this.getProfileData();
		}, 500);
	}

	ionViewWillEnter() {
		this.subscription = new Subscription();
		this.profileForm = this.initializeForm();
		this.companyAddressForm = this.companyAddress();
	}

	initializeForm() {
		return this.formBuilder.group({
			name: new FormControl(null, {
				validators: Validators.compose([
					Validators.required,
					CustomValidator.noWhiteSpaceValidator,
				]),
			}),
			nameAr: new FormControl(null, {
				validators: Validators.compose([
					Validators.required,
					CustomValidator.arabicNameValidator,
				]),
			}),
			email: new FormControl(null, {
				validators: Validators.compose([
					Validators.required,
					CustomValidator.emailValidator,
				]),
			}),
			phone_no: new FormControl(null, {
				validators: Validators.compose([
					Validators.required,
					CustomValidator.phoneValidator,
				]),
			}),
			contact_person_fullname: new FormControl(null, {
				validators: Validators.compose([
					Validators.required,
					CustomValidator.nameValidator,
				]),
			}),
			contact_person_mobile_no: new FormControl(null, {
				validators: Validators.compose([
					Validators.required,
					CustomValidator.phoneValidator,
				]),
			}),
			company_legal_name: new FormControl(null, {
				validators: Validators.compose([
					Validators.required,
					CustomValidator.nameValidator,
				]),
			}),
			company_legal_name_ar: new FormControl(null, {
				validators: Validators.compose([
					Validators.required,
					CustomValidator.arabicNameValidator,
				]),
			}),
			main_brand: new FormControl(null, {
				validators: Validators.compose([CustomValidator.nameValidator]),
			}),
			main_brand_ar: new FormControl(null, {
				validators: Validators.compose([CustomValidator.arabicNameValidator]),
			}),
			brand_logo_name: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			company_logo_name: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			company_cr_number: new FormControl(null, {
				validators: Validators.compose([
					Validators.required,
					CustomValidator.crValidator,
				]),
			}),
			vat_certificate_number: new FormControl(null, {
				validators: Validators.compose([
					Validators.required,
					CustomValidator.vatNoValidator,
				]),
			}),
			company_cr_file: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			vat_certificate_file: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			national_address_file: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			iso_country_code: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			iban_check_digits: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			iban: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			sepa_member: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			bank_name_english: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			bank_name_arabic: new FormControl(null, {
				validators: Validators.compose([CustomValidator.arabicNameValidator]),
			}),
			country_en_payment: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			country_ar_payment: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			branch_code: new FormControl(null, {
				validators: Validators.compose([
					Validators.minLength(5),
					Validators.maxLength(10),
				]),
			}),
			bank_account_name_english: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			bank_account_name_arabic: new FormControl(null, {
				validators: Validators.compose([CustomValidator.arabicNameValidator]),
			}),
			bank_account_iban: new FormControl(null, {
				validators: Validators.compose([
					Validators.required,
					Validators.maxLength(24),
					CustomValidator.blockSpecialCharacter
				]),
			}),
			swift_code: new FormControl(null, {
				validators: Validators.compose([
					Validators.required,
					Validators.minLength(5),
					Validators.maxLength(11),
				]),
			}),
			company_address: new FormArray(
				[this.companyAddress()],
				Validators.required
			),
		});
	}

	companyAddress(): FormGroup {
		return this.formBuilder.group({
			hq_address_english: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator]),
			}),
			hq_address_arabic: new FormControl(null, {
				validators: Validators.compose([
					Validators.required,
					CustomValidator.arabicNameValidator,
					CustomValidator.noWhiteSpaceValidator
				]),
			}),
			country_en: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			country_ar: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			region_en: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			region_ar: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			city_en: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			city_ar: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			zip_en: new FormControl(null, {
				validators: Validators.compose([
					Validators.required,
					Validators.minLength(5),
					Validators.maxLength(6),
				]),
			}),
			land_line: new FormControl(null, {
				validators: Validators.compose([
					Validators.required,
					CustomValidator.landlineValidator,
				]),
			}),
		});
	}

	get company_address() {
		return this.profileForm.controls['company_address'] as FormArray;
	}

	addMoreAddress() {
		this.company_address.push(this.companyAddress());
		this.addressDD[Object.keys(this.addressDD).length] = {
			region: [],
			city: [],
		};
		this.company_address.controls[this.companyAddress.length + 1]['controls'][
			'city_en'
		].disable();
		this.company_address.controls[this.companyAddress.length + 1]['controls'][
			'region_en'
		].disable();
	}

	removeAddress(index: any) {
		this.company_address.removeAt(index);
		let keyArr = Object.keys(this.addressDD);
		for (let i = index; i < keyArr.length; i++) {
			if (i < keyArr.length - 1) {
				this.addressDD[i] = JSON.parse(JSON.stringify(this.addressDD[i + 1]));
			} else {
				delete this.addressDD[i];
			}
		}
	}

	upload(files, fieldname) {
		files.preventDefault();
		files.stopPropagation();

		this.files = files['target']['files'][0].name;
		if (
			files &&
			files['target'] &&
			files['target']['files'] &&
			files['target']['files'][0]
		) {
			const max_size = 2097152;
			if (files['target']['files'][0].size > max_size) {
				this.message = 'Maximum size allowed is ' + max_size / 1048576 + 'MB';
				this.files = null;
				this.fileToUpload = null;
				files.target.value = null;
				this.toastService.displayToast(this.message, null, 'warning');
				return false;
			}
			if (fieldname == 'company-logo' || fieldname == 'brand-logo') {
				if (files['target']['files'][0].type == 'image/png') {
					this.message = null;
					const reader = new FileReader();
					reader.readAsDataURL(files['target']['files'][0]);
					reader.onload = (event: any) => {
						if (fieldname == 'company-logo') {
							this.companyLogoPreview = event.target.result;
						} else {
							this.brandLogoPreview = event.target.result;
						}
					};
					const obj = {
						fileUploaded: true,
					};
					if (fieldname == 'company-logo') {
						this.companyLogoFile = files['target']['files'][0];
						this.companyLogoName = files['target']['files'][0].name;
					} else {
						this.brandLogoFile = files['target']['files'][0];
						this.brandLogoName = files['target']['files'][0].name;
					}
				} else {
					this.message = 'Only png images are allowed';
					this.fileToUpload = null;
					files.target.value = null;
					this.files = null;
					this.toastService.displayToast(this.message, null, 'warning');
					return false;
				}
			}

			if (fieldname == 'company-cr-number') {
				this.isCompanyCRNoFile = false;
				setTimeout(() => {
					const setTooltipWidth = this.document.getElementsByClassName(
						'company-cr-no-tooltip'
					) as HTMLCollectionOf<HTMLElement>;
					if (this.companyCrNoElem) {
						const companyCrDivWidth =
							this.companyCrNoElem.nativeElement.offsetWidth;
						if (setTooltipWidth[0]) {
							setTooltipWidth[0].style.width = companyCrDivWidth + 'px';
						}
					}
				}, 500);
			}
			if (fieldname == 'vat-certificate-no') {
				this.isVatCertificateNoFile = false;
				setTimeout(() => {
					const setTooltipWidth = this.document.getElementsByClassName(
						'vat-certificate-no-tooltip'
					) as HTMLCollectionOf<HTMLElement>;
					if (this.vatCertificateNoElem) {
						const companyVatDivWidth =
							this.vatCertificateNoElem.nativeElement.offsetWidth;
						if (setTooltipWidth[0]) {
							setTooltipWidth[0].style.width = companyVatDivWidth + 'px';
						}
					}
				}, 500);
			}
			if (fieldname == 'brand-logo') {
				this.isBrandLogoFile = false;
			}
			if (fieldname == 'company-logo') {
				this.isCompanyLogoFile = false;
				setTimeout(() => {
					const setTooltipWidth = this.document.getElementsByClassName(
						'company-logo-tooltip'
					) as HTMLCollectionOf<HTMLElement>;
					if (this.companyLogoNameElem) {
						const companyLogoDivHeight =
							this.companyLogoNameElem.nativeElement.offsetWidth;
						if (setTooltipWidth[0]) {
							setTooltipWidth[0].style.width = companyLogoDivHeight + 'px';
						}
					}
				}, 500);
			}
			if (fieldname == 'national-address-file') {
				this.isNationalAddressFile = false;
				setTimeout(() => {
					const setTooltipWidth = this.document.getElementsByClassName(
						'national-address-file-tooltip'
					) as HTMLCollectionOf<HTMLElement>;
					if (this.nationalAddressFileElem) {
						const companyVatDivWidth =
							this.nationalAddressFileElem.nativeElement.offsetWidth;
						if (setTooltipWidth[0]) {
							setTooltipWidth[0].style.width = companyVatDivWidth + 'px';
						}
					}
				}, 500);
			}

			if (
				fieldname == 'company-cr-number' ||
				fieldname == 'vat-certificate-no' ||
				fieldname == 'national-address-file'
			) {
				if (
					files['target']['files'][0].type.includes('.pdf') ||
					files['target']['files'][0].type.includes('application/pdf') ||
					files['target']['files'][0].type.includes('.docx') ||
					files['target']['files'][0].type.includes('application/docx')
				) {
					this.message = null;
					const reader = new FileReader();
					const obj = {
						fileUploaded: true,
					};
					if (fieldname == 'company-cr-number') {
						this.companyCRNoFile = files['target']['files'][0];
						this.companyCRNoName = files['target']['files'][0].name;
						this.profileForm.controls['company_cr_file'].setValue(
							this.companyCRNoFile
						);
					} else if (fieldname == 'national-address-file') {
						this.nationalAddressFile = files['target']['files'][0];
						this.nationalAddressFileName = files['target']['files'][0].name;
						this.profileForm.controls['national_address_file'].setValue(
							this.nationalAddressFile
						);
					} else {
						this.vatCertificateNoFile = files['target']['files'][0];
						this.vatCertificateNoName = files['target']['files'][0].name;
						this.profileForm.controls['vat_certificate_file'].setValue(
							this.vatCertificateNoFile
						);
					}
				} else {
					this.message = 'Only PDF and DOCX files are allowed';
					this.fileToUpload = null;
					files.target.value = null;
					this.files = null;
					this.toastService.displayToast(this.message, null, 'warning');
					return false;
				}
				files.target.value = null;
			}
		}
	}

	remove(event, fieldname) {
		event.preventDefault();
		event.stopPropagation();
		if (fieldname == 'company-logo') {
			this.companyLogoFile = null;
			this.companyLogoName = null;
			this.companyLogoPreview = null;
		} else if (fieldname == 'brand-logo') {
			this.brandLogoPreview = null;
			this.brandLogoFile = null;
			this.brandLogoName = null;
		} else if (fieldname == 'company-cr-number') {
			this.companyCRNoFile = null;
			this.companyCRNoName = null;
		} else if (fieldname == 'national-address-file') {
			this.nationalAddressFile = null;
			this.nationalAddressFileName = null;
		} else {
			this.vatCertificateNoFile = null;
			this.vatCertificateNoName = null;
		}
	}

	changePassword(event: any) {
		if (!this.showPassword) {
			this.showPassword = true;
			this.passwordText = 'hidePassword';
		} else {
			this.showPassword = false;
			this.passwordText = 'changePassword';
		}
	}

	openChangePasswordModal() {
		let options: any = {
			id: 'changePasswordModal',
			cssClass: 'change-password-modal',
		};
		options['component'] = ChangePasswordComponent;
		this.modalService.openModal(options);
	}

	getCountryList() {
		const params = {
			dropdown: true,
		};
		this.subscription.add(
			this.dataService.get(URLS.countryList, params).subscribe(
				(data) => {
					if (data['code'] == 200) {
						this.countryList = [];
						if (
							data['data'] &&
							data['data']['result'] &&
							data['data']['result'].length
						) {
							let list =
								data['data'] &&
									data['data']['result'] &&
									data['data']['result'].length
									? data['data']['result']
									: [];
							list.forEach((country) => {
								let obj = {
									id: country.country_id ? country.country_id : '1',
									name: this.commonService.domDirectionLTR
										? country.country_en
											? country.country_en
											: ''
										: country.country_ar
											? country.country_ar
											: '',
									nameAr: country.country_ar ? country.country_ar : '',
									countryCode: country.country_code
										? country.country_code
										: '966',
								};
								this.countryList.push(obj);
							});
						}
					} else {
						if (data['code'] != 204) {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
					}
				},
				(error) => {
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}

	getRegions(id?, index?) {
		const params = {};
		params['dropdown'] = true;
		if (id) {
			params['countries'] = Number(id);
		}
		this.subscription.add(
			this.dataService.get(URLS.regionsList, params).subscribe(
				(data) => {
					if (data['code'] == 200) {
						this.regionList = [];
						if (
							data['data'] &&
							data['data']['result'] &&
							data['data']['result'].length
						) {
							let list =
								data['data'] &&
									data['data']['result'] &&
									data['data']['result'].length
									? data['data']['result']
									: [];
							list.forEach((regions) => {
								let obj = {
									id: regions.region_id ? regions.region_id : '',
									name: this.commonService.domDirectionLTR
										? regions.region_en
											? regions.region_en
											: ''
										: regions.region_ar
											? regions.region_ar
											: '',
									nameAr: regions.region_ar ? regions.region_ar : '',
									country_id: regions.country_id ? regions.country_id : 1,
								};
								this.regionList.push(obj);
							});
							if (index >= 0) {
								this.addressDD[index]['region'] = JSON.parse(
									JSON.stringify(this.regionList)
								);
								this.company_address.controls[index]['controls'][
									'region_en'
								].enable();
							}
						}
					} else {
						if (data['code'] != 204) {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
					}
				},
				(error) => {
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}

	getCityList(id?, index?) {
		const params = {};
		params['dropdown'] = true;
		if (id) {
			params['regions'] = Number(id);
		}

		this.subscription.add(
			this.dataService.get(URLS.cityList, params).subscribe(
				(data) => {
					if (data['code'] == 200) {
						this.cityList = [];
						if (
							data['data'] &&
							data['data']['result'] &&
							data['data']['result'].length
						) {
							let list =
								data['data'] &&
									data['data']['result'] &&
									data['data']['result'].length
									? data['data']['result']
									: [];
							list.forEach((city) => {
								let obj = {
									id: city.city_id ? city.city_id : '',
									name: this.commonService.domDirectionLTR
										? city.city_en
											? city.city_en
											: ''
										: city.city_ar
											? city.city_ar
											: '',
									nameAr: city.city_ar ? city.city_ar : '',
									region_id: city.region_id ? city.region_id : '',
								};
								this.cityList.push(obj);
							});
							if (index >= 0) {
								this.addressDD[index]['city'] = JSON.parse(
									JSON.stringify(this.cityList)
								);
								this.company_address.controls[index]['controls'][
									'city_en'
								].enable();
							}
						}
					} else {
						if (data['code'] != 204) {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
					}
				},
				(error) => {
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}

	onCityChange(value, index) {
		if (value) {
			this.company_address.controls[index]['controls']['city_ar'].setValue(
				value.id
			);
			this.company_address.controls[index]['controls']['region_en'].setValue(
				value.region_id
			);
			this.company_address.controls[index]['controls']['region_ar'].setValue(
				value.region_id
			);

			for (let n = 0; n < this.cityList.length; n++) {
				for (let k = 0; k < this.regionList.length; k++) {
					if (this.cityList[n]['region_id'] == this.regionList[k].id) {
						this.company_address.controls[index]['controls'][
							'country_en'
						].setValue(this.regionList[k].country_id);
						this.company_address.controls[index]['controls'][
							'country_ar'
						].setValue(this.regionList[k].country_id);
					}
				}
			}
		}
	}

	onCityClear(event: any, index) {
		this.company_address.controls[index]['controls']['region_en'].reset();
		this.company_address.controls[index]['controls']['region_ar'].reset();
		this.company_address.controls[index]['controls']['city_ar'].reset();
	}

	onRegionChange(value, index) {
		if (value) {
			this.company_address.controls[index]['controls']['region_ar'].setValue(
				value.id
			);
			this.company_address.controls[index]['controls']['country_en'].setValue(
				value.country_id
			);
			this.company_address.controls[index]['controls']['country_ar'].setValue(
				value.country_id
			);
			this.company_address.controls[index]['controls']['city_en'].reset();
			this.company_address.controls[index]['controls']['city_ar'].reset();
			this.addressDD[index]['city'] = [];
			this.getCityList(value.id, index);
		}
	}

	onRegionClear(event: any, index) {
		this.company_address.controls[index]['controls']['region_ar'].reset();
		this.company_address.controls[index]['controls']['city_en'].reset();
		this.company_address.controls[index]['controls']['city_ar'].reset();
		this.addressDD[index]['city'] = [];
	}

	onCountryChange(event: any, index) {
		if (event) {
			this.company_address.controls[index]['controls']['country_ar'].setValue(
				event.id
			);
			this.company_address.controls[index]['controls']['region_ar'].reset();
			this.company_address.controls[index]['controls']['region_en'].reset();
			this.company_address.controls[index]['controls']['city_ar'].reset();
			this.company_address.controls[index]['controls']['city_en'].reset();
			this.company_address.controls[index]['controls']['city_en'].disable();
			this.addressDD[index]['region'] = [];
			this.addressDD[index]['city'] = [];
			this.getRegions(event.id, index);
		}
	}

	onCountryClear(event: any, index) {
		this.company_address.controls[index]['controls']['country_ar'].reset();
	}

	onBankCountryChange(event: any) {
		if (event) {
			this.profileForm.controls['country_ar_payment'].setValue(event.id);
		}
	}

	onBankCountryClear() {
		this.profileForm.controls['country_ar_payment'].reset();
	}

	phoneClick(e) {
		if (e.keyCode < 48 || e.keyCode > 57) {
			e.preventDefault();
		}
	}

	getProfileData() {
		this.subscription.add(
			this.dataService.get(URLS.supplierDetails).subscribe(
				(data) => {
					if (data['code'] == 200) {
						if (data['data']) {
							this.editData = {
								name: data['data']['supplier_name_en']
									? data['data']['supplier_name_en']
									: '',
								nameAr: data['data']['supplier_name_ar'],
								phoneNo: data['data']['supplier_mobile'],
								email: data['data']['supplier_email'],
								legalName: data['data']['supplier_legal_name_en']
									? data['data']['supplier_legal_name_en']
									: '',
								legalNameAr: data['data']['supplier_legal_name_ar'],
								is2FaEnabled: data['data']['is_2fa_enabled'],
								crNo: data['data']['supplier_cr_no'],
								vatNo: data['data']['supplier_vat_no'],
								companyLogoUrl: data['data']['supplier_logo_url'],
								brandName: data['data']['brand_name_en']
									? data['data']['brand_name_en']
									: '',
								brandNameAr: data['data']['brand_name_ar'],
								brandLogoUrl: data['data']['brand_logo_url'],
								companyRegistrationNo: data['data']['company_registration_no'],
								vatUrl: data['data']['supplier_vat_url'],
								crUrl: data['data']['supplier_cr_url'],
								nationalAddressUrl: data['data']['national_address_url'],
								isMainBrand: data['data']['is_main_brand'],
								brandId: data['data']['brand_id'],
								contactPersonName: data['data']['contact_person_name'],
								contactNo: data['data']['contact_no'],
								countryCode: data['data']['country_code']
									? data['data']['country_code']
									: '966',
								locations: data['data']['locations'],
								account_iban: data['data']['supplier_bank_details']
									? data['data']['supplier_bank_details']['account_iban']
									: '',
								bank_account_name_en: data['data']['supplier_bank_details']
									? data['data']['supplier_bank_details']['bank_account_name']
									: '',
								bank_account_name_ar: data['data']['supplier_bank_details']
									? data['data']['supplier_bank_details'][
									'bank_account_name_ar'
									]
									: '',
								bank_name_en: data['data']['supplier_bank_details']
									? data['data']['supplier_bank_details']['bank_name']
									: '',
								bank_name_ar: data['data']['supplier_bank_details']
									? data['data']['supplier_bank_details']['bank_name_ar']
									: '',
								branch_code: data['data']['supplier_bank_details']
									? data['data']['supplier_bank_details']['branch_code']
									: '',
								swift_code: data['data']['supplier_bank_details']
									? data['data']['supplier_bank_details']['swift_code']
									: '',
								bank_country_id: data['data']['supplier_bank_details']
									? data['data']['supplier_bank_details']['country_id']
									: null,
							};
							if (this.editData.locations) {
								for (let i = 0; i < this.editData.locations.length; i++) {
									this.addressDD[i] = {
										region: [],
										city: [],
									};
									this.getRegions(this.editData.locations[i]['country_id'], i);
									this.getCityList(this.editData.locations[i]['region_id'], i);
									if (i > 0) {
										this.company_address.push(this.companyAddress());
									}
								}
							}

							this.setFormValue(this.editData);
						}
					} else {
						if (data['code'] != 204) {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
					}
				},
				(error) => {
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}

	setFormValue(data) {
		this.profileForm.controls['name'].setValue(data.name);
		this.profileForm.controls['nameAr'].setValue(data.nameAr);
		this.profileForm.controls['email'].setValue(data.email);
		this.profileForm.controls['phone_no'].setValue(data.phoneNo);
		this.profileForm.controls['contact_person_fullname'].setValue(
			data.contactPersonName
		);
		this.profileForm.controls['contact_person_mobile_no'].setValue(
			data.contactNo
		);
		this.profileForm.controls['company_legal_name'].setValue(data.legalName);
		this.profileForm.controls['company_legal_name_ar'].setValue(
			data.legalNameAr
		);
		this.profileForm.controls['company_cr_number'].setValue(data.crNo);
		this.profileForm.controls['vat_certificate_number'].setValue(data.vatNo);
		this.profileForm.controls['main_brand'].setValue(data.brandName);
		this.profileForm.controls['main_brand_ar'].setValue(data.brandNameAr);

		this.profileForm.controls['bank_account_iban'].setValue(data.account_iban);
		this.profileForm.controls['bank_account_name_english'].setValue(
			data.bank_account_name_en
		);
		this.profileForm.controls['bank_account_name_arabic'].setValue(
			data.bank_account_name_ar
		);
		this.profileForm.controls['bank_name_english'].setValue(data.bank_name_en);
		this.profileForm.controls['bank_name_arabic'].setValue(data.bank_name_ar);
		this.profileForm.controls['branch_code'].setValue(data.branch_code);
		this.profileForm.controls['country_en_payment'].setValue(
			data.bank_country_id
		);
		this.profileForm.controls['country_ar_payment'].setValue(
			data.bank_country_id
		);
		this.profileForm.controls['swift_code'].setValue(data.swift_code);

		this.countryCode = data.countryCode;

		if (data.brandLogoUrl) {
			this.profileForm.controls['brand_logo_name'].setValue(data.brandLogoUrl);
			this.brandLogoPreview = data.brandLogoUrl;
			this.brandLogoFile = data.brandLogoUrl;
			this.brandLogoFile = this.brandLogoFile.split('/');
			this.brandLogoName = this.brandLogoFile[
				this.brandLogoFile.length - 1
			].replace('.webp', '');
		}
		if (data.companyLogoUrl) {
			this.profileForm.controls['company_logo_name'].setValue(
				data.companyLogoUrl
			);
			this.companyLogoPreview = data.companyLogoUrl;
			this.companyLogoFile = data.companyLogoUrl;
			this.companyLogoFile = this.companyLogoFile.split('/');
			this.companyLogoName = this.companyLogoFile[
				this.companyLogoFile.length - 1
			].replace('.webp', '');
		}
		if (data.crUrl) {
			this.profileForm.controls['company_cr_file'].setValue(data.crUrl);
			this.companyCRNoFile = data.crUrl;
			this.companyCRNoFile = this.companyCRNoFile.split('/');
			this.companyCRNoName = this.companyCRNoFile[
				this.companyCRNoFile.length - 1
			]
				.replace('.pdf', '')
				.replace('.docx', '');
		}
		if (data.vatUrl) {
			this.profileForm.controls['vat_certificate_file'].setValue(data.vatUrl);
			this.vatCertificateNoFile = data.vatUrl;
			this.vatCertificateNoFile = this.vatCertificateNoFile.split('/');
			this.vatCertificateNoName = this.vatCertificateNoFile[
				this.vatCertificateNoFile.length - 1
			]
				.replace('.pdf', '')
				.replace('.docx', '');
		}
		if (data.nationalAddressUrl) {
			this.profileForm.controls['national_address_file'].setValue(
				data.nationalAddressUrl
			);
			this.nationalAddressFile = data.nationalAddressUrl;
			this.nationalAddressFile = this.nationalAddressFile.split('/');
			this.nationalAddressFileName = this.nationalAddressFile[
				this.nationalAddressFile.length - 1
			]
				.replace('.pdf', '')
				.replace('.docx', '');
		}
		if (data.contact_person_name) {
			this.profileForm.controls['contact_person_fullname'].setValue(
				data.contact_person_name
			);
		}
		if (data.contact_no) {
			this.profileForm.controls['contact_person_mobile_no'].setValue(
				data.contact_no
			);
		}

		if (data.locations) {
			for (const j in this.company_address.controls) {
				for (const k in this.companyAddressForm.controls) {
					if (k == 'hq_address_english') {
						this.company_address.controls[j]['controls'][k].setValue(
							data.locations[j]['supplier_address_en']
						);
					}
					if (k == 'hq_address_arabic') {
						this.company_address.controls[j]['controls'][k].setValue(
							data.locations[j]['supplier_address_ar']
						);
					}
					if (k == 'land_line') {
						this.company_address.controls[j]['controls'][k].setValue(
							data.locations[j]['contact_no']
						);
					}
					if (k == 'zip_en') {
						this.company_address.controls[j]['controls'][k].setValue(
							data.locations[j]['zipcode']
						);
					}
					if (k == 'city_en' || k == 'city_ar') {
						this.company_address.controls[j]['controls'][k].setValue(
							data.locations[j]['city_id']
						);
					}
					if (k == 'country_en' || k == 'country_ar') {
						this.company_address.controls[j]['controls'][k].setValue(
							data.locations[j]['country_id']
								? data.locations[j]['country_id']
								: 1
						);
					}
					if (k == 'region_en' || k == 'region_ar') {
						this.company_address.controls[j]['controls'][k].setValue(
							data.locations[j]['region_id']
						);
					}
				}
			}
		}
	}

	onSubmit() {
		this.isCompanyCRNoFile = true;
		this.isVatCertificateNoFile = true;
		this.isBrandLogoFile = true;
		this.isCompanyLogoFile = true;
		this.isNationalAddressFile = true;
		if (this.companyCRNoFile) this.isCompanyCRNoFile = false;
		if (this.vatCertificateNoFile) this.isVatCertificateNoFile = false;
		if (this.brandLogoFile) this.isBrandLogoFile = false;
		if (this.companyLogoFile) this.isCompanyLogoFile = false;
		if (this.nationalAddressFile) this.isNationalAddressFile = false;

		this.locationParams = [];
		let myLoation = {};
		let paramsLocation = {};
		for (const i in this.profileForm.controls) {
			if (i == 'company_address') {
				for (const j in this.company_address.controls) {
					for (const k in this.companyAddressForm.controls) {
						if (
							k == 'hq_address_english' &&
							this.company_address.controls[j]['controls'][k].value
						) {
							paramsLocation['supplier_address_en'] =
								this.company_address.controls[j]['controls'][k].value;
						}
						if (
							k == 'hq_address_arabic' &&
							this.company_address.controls[j]['controls'][k].value
						) {
							paramsLocation['supplier_address_ar'] =
								this.company_address.controls[j]['controls'][k].value;
						}
						if (
							k == 'land_line' &&
							this.company_address.controls[j]['controls'][k].value
						) {
							paramsLocation['contact_no'] =
								this.company_address.controls[j]['controls'][k].value;
						}
						if (
							k == 'city_en' &&
							this.company_address.controls[j]['controls'][k].value
						) {
							paramsLocation['city_id'] =
								this.company_address.controls[j]['controls'][k].value;
						}
						if (
							k == 'zip_en' &&
							this.company_address.controls[j]['controls'][k].value
						) {
							paramsLocation['zipcode'] =
								this.company_address.controls[j]['controls'][k].value;
						}
						this.company_address.controls[j]['controls'][k].markAsDirty();
						this.company_address.controls[j]['controls'][k].markAsTouched();
						this.company_address.controls[j]['controls'][
							k
						].updateValueAndValidity();
					}
					myLoation = { ...paramsLocation };
					this.locationParams.push(myLoation);
				}
			} else {
				this.profileForm.controls[i].markAsDirty();
				this.profileForm.controls[i].updateValueAndValidity();
			}
		}

		if (this.profileForm.invalid) return;

		if (
			!this.companyCRNoFile ||
			!this.vatCertificateNoFile ||
			!this.brandLogoFile ||
			!this.companyLogoFile ||
			!this.nationalAddressFile
		)
			return;

		const params = new FormData();
		if (this.profileForm.value.name) {
			params.append('supplier_name_en', this.profileForm.value.name);
		}
		if (this.profileForm.value.nameAr) {
			params.append('supplier_name_ar', this.profileForm.value.nameAr);
		}
		if (this.profileForm.value.email) {
			params.append('supplier_email', this.profileForm.value.email);
		}
		if (this.profileForm.value.phone_no) {
			params.append('supplier_mobile', this.profileForm.value.phone_no);
		}
		if (this.profileForm.value.company_legal_name) {
			params.append(
				'supplier_legal_name_en',
				this.profileForm.value.company_legal_name
			);
		}
		if (this.profileForm.value.company_legal_name_ar) {
			params.append(
				'supplier_legal_name_ar',
				this.profileForm.value.company_legal_name_ar
			);
		}
		if (this.profileForm.value.contact_person_fullname) {
			params.append(
				'contact_person_name',
				this.profileForm.value.contact_person_fullname
			);
		}
		if (this.profileForm.value.contact_person_mobile_no) {
			params.append(
				'contact_no',
				this.profileForm.value.contact_person_mobile_no
			);
		}
		if (this.profileForm.value.vat_certificate_number) {
			params.append(
				'supplier_vat_no',
				this.profileForm.value.vat_certificate_number
			);
		}
		if (this.profileForm.value.company_cr_number) {
			params.append('supplier_cr_no', this.profileForm.value.company_cr_number);
		}
		if (this.companyLogoFile) {
			params.append('supplier_logo_url', this.companyLogoFile);
		}
		if (this.companyCRNoFile) {
			params.append('supplier_cr_url', this.companyCRNoFile);
		}
		if (this.vatCertificateNoFile) {
			params.append('supplier_vat_url', this.vatCertificateNoFile);
		}
		if (this.countryCode) {
			params.append('country_code', this.countryCode);
		}

		if (this.locationParams) {
			params.append('supplier_locations', JSON.stringify(this.locationParams));
		}

		if (this.nationalAddressFile) {
			params.append('national_address_url', this.nationalAddressFile);
		}

		// Required Code

		const bankDetails = {
			bank_name: this.profileForm.value.bank_name_english,
			bank_account_name: this.profileForm.value.bank_account_name_english,
			account_iban: this.profileForm.value.bank_account_iban.toString(),
			swift_code: this.profileForm.value.swift_code,
			country_id: +this.profileForm.value.country_en_payment,
		};

		const encryptedData = this.commonService.encryptData(
			JSON.stringify(bankDetails)
		);
		const bankParams = {
			data: encryptedData,
		};
		this.addBank(bankParams);

		this.updateProfile(params);
	}

	updateProfile(params: any) {
		this.isLoading = true;
		this.subscription.add(
			this.dataService.put(URLS.supplierUpdate, params).subscribe(
				(data) => {
					if (data['code'] == 200 && data['data']) {
						const element = this.document.querySelector('#scrollId');
						if (element) {
							element.scrollIntoView();
						}
						this.isLoading = false;
						this.toastService.displayToast(data['message'], null, 'success');

					} else {
						this.isLoading = false;
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				},
				(error) => {
					this.isLoading = false;
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}

	addBank(params) {
		this.subscription.add(
			this.dataService.post(URLS.addBankDetails, params).subscribe(
				(data) => {
					if (data && data['code'] === 200) {
					} else {
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				},
				(error) => {
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}
	onEmailEditClick() {
		this.isEmailDisabled = false;
		setTimeout(() => {
			if (this.emailInput) {
				this.emailInput.setFocus();
			}
		}, 300);
	}

	onPhoneEditClick() {
		this.isPhoneDisabled = false;
		setTimeout(() => {
			if (this.phoneInput) {
				this.phoneInput.setFocus();
			}
		}, 300);
	}

	restrictSpecialChar(event) {
		var k;
		k = event.charCode; //         k = event.keyCode;  (Both can be used)
		return (
			(k > 64 && k < 91) ||
			(k > 96 && k < 123) ||
			k == 8 ||
			k == 32 ||
			(k >= 48 && k <= 57)
		);
	}

	numberOnlyValidation(event: any) {
		const pattern = /[0-9]/;
		let inputChar = String.fromCharCode(event.charCode);

		if (!pattern.test(inputChar)) {
			event.preventDefault();
		}
	}

	ionViewWillLeave() {
		this.subscription.unsubscribe();
	}
}
