import { Location } from "@angular/common";
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../_services/common.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard {
	isModalOpen = false
	modal: any
	constructor(private router: Router,
		private location: Location,
		public commonService: CommonService
	) { }
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		let routeUrl: any;
		if (next.url && next.url.length > 0) {
			routeUrl = next.url[0].path;
		}

		const accessToken = localStorage.getItem('accessToken');
		const otpVerified = localStorage.getItem('OTP_Verified');


		if ((accessToken || this.commonService.accessToken) && otpVerified) {
			if (routeUrl && (routeUrl.includes('login') || routeUrl.includes('registration') || routeUrl.includes('forgot-password'))) {
				if (this.location.getState()) {
					this.location.back();
				} else {
					this.router.navigate(['/account-details/marketplace-dashboard']);
				}
				return false;
			}
			return true;
		} else {
			if (routeUrl && (routeUrl.includes('login') || routeUrl.includes('registration') || routeUrl.includes('forgot-password'))) {
				return true;
			}

			const uuid = localStorage.getItem('uuid');
			const mobileNo = localStorage.getItem('mobile_no');
			if (uuid && mobileNo) {
				if (routeUrl && routeUrl.includes('reset-password')) {
					return true;
				}
			}

		}
		// not logged in so redirect to login page with the return url
		this.router.navigate(['/login']);
		return false;
	}
	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

		return this.canActivate(childRoute, state)
	}

}
